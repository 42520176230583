import * as React from 'react';
import PropTypes from 'prop-types';
import { createStyles } from '../../styles';
import { useTheme } from '../../styles';
import DelayUnmounting from '../DelayUnmounting';
import ScrollableViews from '../ScrollableViews';
import { useMemoCallback } from '../../hooks';

const useStyles = createStyles((theme, props) => ({
	root: {
		width: '100%',
		position: 'relative',
		display: 'flex',
		willChange: 'transform',
		transition: theme.transitions.create(props.noTransform ? 'margin' : 'transform', {
			easing: theme.transitions.easing.easeInOut,
			duration: theme.transitions.duration.complex * 1.5
		})
	},
	container: {
		width: '100%',
		maxWidth: '100%',
		flex: '0 0 100%',
		display: 'flex',
		alignSelf: 'flex-start'
	}
}), {
	name: 'RaSlidableViews'
});

function SlidableViews(props) {
	const {
		classes: classesProp,
		className,
		children,
		value: valueProp = 0,
		delay: delayProp,
		keepMounted = false,
		mountedSlides = [],
		noTransform = false,
		animated = true,
		swipeable = false,
		onChangeCursor,
		CarouselProps,
		...rest
	} = props;

	const { classes } = useStyles(props);
	const theme = useTheme();

	const getValue = useMemoCallback(() => {
		const childrenCount = children.length - 1;
		let value = valueProp;
		if (isNaN(value) || value < 0) {
			value = 0;
		}
		if (value > childrenCount) {
			value = childrenCount;
		}
		return value;
	});

	const value = getValue();
	const delay = delayProp !== undefined ? delayProp : theme.transitions.duration.complex;

	const slideRefs = React.Children.map(children, () => React.createRef());
	const slideRef = slideRefs[value];
	const containerRef = React.useRef();

	const [height, setHeight] = React.useState(null);

	const updateHeight = useMemoCallback(() => {
		const clientHeight = slideRef?.current?.clientHeight;
		if (height !== clientHeight) {
			setHeight(clientHeight);
		}
	});

	const handleOnRest = useMemoCallback((...args) => {
		if (animated) {
			updateHeight();
		}
		if (typeof onChangeCursor === 'function') {
			onChangeCursor(...args);
		}
	});

	React.useEffect(() => {
		if (!animated) return;
		const containerNode = containerRef.current;
		if (!swipeable && containerNode) {
			containerNode.ontransitionend = updateHeight;
			return () => {
				containerNode.ontransitionend = null;
			};
		}
	}, [updateHeight, swipeable, animated]);

	const slides = React.Children.map(children, ((child, key) => {
		if (!child) return null;

		if (!slideRefs[key]) {
			slideRefs[key] = React.createRef();
		}

		const isMounted = mountedSlides.includes(key) || value === key;

		return (
			<div
				style={animated ? {
					height: value === key ? 'auto' : height || 'auto'
				} : null}
				ref={slideRefs[key]}
				className={classes.container}
			>
				{keepMounted ? (
					child
				) : (
					<DelayUnmounting
						isMounted={isMounted}
						delay={delay}
					>
						{child}
					</DelayUnmounting>
				)}
			</div>
		);
	}));

	return (
		swipeable ? (
			<ScrollableViews
				{...rest}
				{...CarouselProps}
				animated={animated}
				cursor={value}
				onRest={handleOnRest}
				fullWidth
				showNavButtons={false}
			>
				{slides}
			</ScrollableViews>
		) : (
			<div
				{...rest}
				ref={containerRef}
				style={{
					...(noTransform ? {
						marginLeft: `-${(value) * 100}%`
					} : {
						transform: `translate3d(-${(value) * 100}%, 0, 0)`
					}),
					transition: !animated ? 'none' : null,
					...props?.style
				}}
				className={classes.root}
			>
				{slides}
			</div>
		)
	);
}

SlidableViews.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	keepMounted: PropTypes.bool,
	mountedSlides: PropTypes.array,
	animated: PropTypes.bool,
	delay: PropTypes.number,
	value: PropTypes.number.isRequired,
	swipeable: PropTypes.bool,
	noTransform: PropTypes.bool,
	CarouselProps: PropTypes.object
};

export default React.memo(SlidableViews);
